import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
//import 'bootstrap/dist/css/bootstrap.min.css'
import './styles.scss'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { AuthProvider } from './lib/providers/auth'
import { CentrifugeProvider } from './lib/providers/socket'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <StrictMode>
        <AuthProvider>
            <CentrifugeProvider>
                <App />
            </CentrifugeProvider>
        </AuthProvider>
    </StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
