import './App.css'
import routes from 'src/routes'
import Layout from 'src/components/layouts/Layout'
import Page404 from 'src/pages/Page404'
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
} from 'react-router-dom'
import { ModalProvider } from './lib/providers/modal'
import { useEffect } from 'react'
import { ToastProvider } from './lib/providers/toast'
import { BreakpointProvider } from './lib/providers/breakpoint'

function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    })
  }, [pathname])

  return null
}

function App() {
  const router = createBrowserRouter([
    {
      element: (
        <BreakpointProvider>
          <ModalProvider>
            <ToastProvider>
              <ScrollToTop />
              <Layout />
            </ToastProvider>
          </ModalProvider>
        </BreakpointProvider>
      ),
      errorElement: <Page404 />,
      children: routes,
    },
  ])

  return <RouterProvider router={router} />
}

export default App
