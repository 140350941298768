import styles from './style.module.css'
import BackButton from '../back-button'
import BidStatus from '../bid-status'
import React from 'react'
import LotInfo from '../lot-info'
import { useBreakpoint } from 'src/lib/providers/breakpoint'

const BackHeader = ({ title, action, lot }) => {
    const { isMobile } = useBreakpoint();

    return (
        <div className={styles.head}>
            <BackButton onClick={action} />
            {isMobile && lot && <LotInfo lot={lot} onlyImage />}
            <div className={styles.heading}>{title}</div>
            {isMobile && <BidStatus />}
        </div>
    )
}

export default BackHeader
