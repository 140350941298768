import { Col, Container, Row } from 'react-bootstrap'
import FaqAccordion from 'src/components/faq-accordion'
import InnerPageWrapper from 'src/components/inner-page-wrapper'

export default function Faq() {
    const data = [
        {
            q: 'What are the benefits of creating a Memora account?',
            a: "Creating a Memora account allows you to participate in exclusive auctions, track your bids, view your watchlist, view your auction history, view your impact through your donations, and receive personalized alerts.",
        },
        {
            q: 'How do I create an account?',
            a: 'To create an account, go to our registration page, provide your email, phone number, and set a password. You will need to verify your identity by uploading a valid photo ID and adding a payment method. Memora uses Stripe to perform identity verification KYC through the highest security standards, ensuring that all transactions are safe and the money comes from a clean source.',
        },
        {
            q: 'Why do I need to verify my identity?',
            a: 'Identity verification is crucial to maintain the integrity of our platform. This step ensures that all bidders are legitimate, prevents fraudulent activity, and guarantees a secure environment for all users.',
        },
        {
            q: 'How do I reset my password?',
            a: <span>Click &apos;Forgot Password&apos; on the login page and follow the instructions sent to your registered email address. If you encounter any issues, please reach out to <a href="mailto:support@memorahouse.io">support@memorahouse.io</a></span>,
        },
        {
            q: 'Can I change my email or payment information?',
            a: "Yes, you can update your email, payment, and shipping information in the 'Account Settings' section of your Memora profile.",
        },
        {
            q: 'How can I delete my account?',
            a: <span>If you wish to delete your account, please contact our support team at <a href="mailto:support@memorahouse.io">support@memorahouse.io</a>. Please note that deleting your account will remove your access to all auctions and any pending transactions.</span>
        },
        {
            q: 'How do I bid in an auction?',
            a: <span>To place a bid, log in to your account, browse through our live or upcoming
            auctions, and click on the item you wish to bid on. Enter your bid amount and
            confirm. Our system will notify you if youve been outbid and allow you to place
            additional bids if needed.</span>
        },
        {
            q: 'What are bidding increments, and how do they work?',
            a: <span>Bidding increments are the minimum amount by which a bid must be increased.
            The increments are set based on the current bid price and are displayed in the
            auction listing.</span>
        },
        {
            q: 'What is a maximum bid, and how does it work?',
            a: <span>A maximum bid allows you to set the highest amount you are willing to pay for
            an item. Memoras auction system will automatically place incremental bids on
            your behalf, up to your maximum bid amount, ensuring you remain the highest
            bidder until your limit is reached.</span>
        },
        {
            q: 'What is a reserve price?',
            a: <span>A reserve price is the minimum amount the charitable foundation is willing to
            accept for an item. If the bidding does not reach this price, the item will not be
            sold.</span>
        },
        {
            q: 'How do I know if I have been outbid?',
            a: <span>If another bidder surpasses your bid, you will receive an email notification. You
            can then choose to place a higher bid if you wish to continue bidding on the
            item.</span>
        },
        {
            q: 'Can I cancel or withdraw a bid?',
            a: <span>No, all bids are binding once placed and cannot be canceled or withdrawn.
Please review your bids carefully before confirming.</span>
        },
        {
            q: 'What happens if I win an auction?',
            a: <span>If you win an auction, you will receive an email with a link to complete your
            payment. Once the payment is processed, our logistics team will coordinate the
            shipping and delivery of your item.</span>
        },
        {
            q: 'What payment methods does Memora accept?',
            a: <span>Memora accepts payments via credit cards, bank transfers, and select
cryptocurrencies through our partner, Stripe.</span>
        },
        {
            q: 'How do I pay if I win an auction?',
            a: <span>Once the auction closes, you will receive an invoice via email with a link to
            complete your payment. You can choose from the available payment methods
            and follow the instructions to finalize your purchase.</span>
        },
        {
            q: 'Is there a Buyers Premium?',
            a: <span>Yes, Memora applies a Buyers Premium, which is a percentage added to the
            final hammer price of the item. The Buyers Premium ranges from 15% to 25%,
            depending on the final bid price. This is the only amount Memora receives, as
            100% of the hammer price goes directly to the charitable foundation chosen by
            the celebrity.</span>
        },
        {
            q: 'Are there any additional fees?',
            a: <span>Additional fees may include shipping, insurance, and authentication costs.
            These fees will be outlined in the invoice and must be paid along with the final
            bid amount.</span>
        },
        {
            q: 'What is the return policy?',
            a: <span>All sales are final, and returns are not accepted unless there is a proven issue
            with authenticity. If you have concerns about an item, please contact
            <a href="mailto:info@memorahouse.io">info@memorahouse.io</a> for assistance.</span>
        },
        {
            q: 'How do I arrange shipping for my auction item?',
            a: <span>After payment is received, our logistics team will coordinate the shipping
            details. Memora works with trusted carriers to ensure safe and timely delivery
            of your items.</span>
        },
        {
            q: 'What shipping options are available?',
            a: <span>Shipping options include standard delivery, express delivery, and insured
            shipping, depending on the size and value of the item. You can select your
            preferred option at checkout.</span>
        },
        {
            q: 'Can I arrange my own shipping?',
            a: <span>Yes, you may choose to use your own carrier. Please notify us within 24 hours
            of making your payment so we can coordinate with your chosen shipping
            provider.</span>
        },
        {
            q: 'When will my item be shipped?',
            a: <span>Items are generally shipped within 710 business days after the payment is
confirmed. You will receive a tracking number once your item is dispatched.</span>
        },
        {
            q: 'Can I track my order?',
            a: <span>Yes, a tracking number will be provided once your item is shipped. You can use
this number to track the progress of your delivery.</span>
        },
        {
            q: 'What should I do if my item is damaged during shipping?',
            a: <span>If your item arrives damaged, please contact <a href="mailto:info@memorahouse.io">info@memorahouse.io</a> within 48
            hours of receiving the item. We will guide you through the process of filing a
            claim and resolving the issue</span>
        },
        {
            q: 'What is a Digital ID, and what information does it contain?',
            a: <span>The Digital ID is a smart contract stored on the blockchain that acts as an
            immutable and traceable digital record of ownership and provenance for an
            auction item. It includes: <br></br>
            <>
  <ul id="1353aa27-1286-8084-bab6-fca6ec9bc097" className="bulleted-list">
    <li style={{ listStyleType: "disc" }}>
      <strong>Ownership:</strong> The Digital ID proves the current and
      historical ownership of the item, verifying who the rightful owner is.
    </li>
  </ul>
  <ul id="1353aa27-1286-80ec-b7bb-c27a5153a7d4" className="bulleted-list">
    <li style={{ listStyleType: "disc" }}>
      <strong>Provenance:</strong> The ID provides a transparent record of the
      item's history, including any previous owners or transactions.
    </li>
  </ul>
  <ul id="1353aa27-1286-80a2-8653-ec7f83d30c17" className="bulleted-list">
    <li style={{ listStyleType: "disc" }}>
      <strong>Financial Transparency:</strong> It records the entire financial
      lifecycle of the item, showing the final hammer price and the allocation
      of funds.
    </li>
  </ul>
  <ul id="1353aa27-1286-8005-b779-cf27d994675e" className="bulleted-list">
    <li style={{ listStyleType: "disc" }}>
      <strong>Traceable Impact:</strong> It tracks how the funds were used by
      the charitable organization linked to the item, providing a clear view of
      the impact your purchase has made.
    </li>
  </ul>
  <ul id="1353aa27-1286-804d-9e54-d69cb9eb678b" className="bulleted-list">
    <li style={{ listStyleType: "disc" }}>
      <strong>Charitable Donation Certificate:</strong> The Digital ID includes
      a certificate that confirms the amount donated, which can be used for tax
      deductions.
    </li>
  </ul>
</>
</span>
        },
        {
            q: 'Why does Memora use Digital IDs?',
            a: <span>Memora uses Digital IDs to bring unprecedented transparency, security, and
            traceability to the world of luxury collectibles and charitable auctions. The
            Digital ID ensures enhanced security, financial and impact transparency, proof
            of authenticity, and a complete record of the items lifecycle and provenance.</span>
        },
        {
            q: 'How do I know my contribution has reached the intended charity?',
            a: <span>Memoras blockchain technology ensures full traceability of all transactions.
            Each donation is recorded on the Digital ID associated with the items smart
            contract, providing a transparent and immutable record. The foundations and
            causes provide us with detailed reports on how donations are utilized, ensuring
            complete transparency.</span>
        },
        {
            q: 'Can I use my Charitable Donation Certificate for tax purposes?',
            a: <span>Yes, once the donation is completed, you will receive a Charitable Donation
            Certificate, which you can use to apply for tax reductions. This certificate will
            be available in your Memora profile and is part of the Digital ID associated with
            your item.</span>
        },
        {
            q: 'How are charitable foundations chosen?',
            a: <span>The charitable foundation is selected by the celebrity donating the item. All
            foundations are vetted by our team and partners to ensure they meet our
            standards and are making a meaningful impact. We only work with
            organizations that are transparent and provide detailed reports on how
            donations are utilized.</span>
        },
        {
            q: 'How does Memora protect against scams and fraud?',
            a: <span>Memora employs advanced AI tools and Stripe Radar to monitor and detect any
            suspicious or fraudulent activity, even if the user has passed identity
            verification KYC. Stripes KYC process is conducted using the highest security
            standards to ensure that all users and transactions are legitimate and funds
            come from a clean source.</span>
        },
        {
            q: 'What buyer protections are in place?',
            a: <span>All items on Memora are authenticated and linked to a Digital ID on the
            blockchain. This ensures full transparency and traceability, protecting buyers
            from counterfeit items and fraudulent sellers. Additionally, our platform
            monitors all transactions and bidding behavior to prevent any suspicious
            activity.</span>
        },
        {
            q: 'How can I report suspicious activity?',
            a: <span>If you notice any suspicious activity or fraudulent behavior, please contact us
            immediately at <a href="mailto:info@memorahouse.io">info@memorahouse.io</a> with detailed information. Our team will
            investigate and take necessary action.</span>
        },
        {
            q: 'Where can I find Memoras Terms and Conditions?',
            a: <span>You can access our Terms and Conditions at the main page.</span>
        },
        {
            q: 'Where can I find Memoras Privacy Policy?',
            a: <span>You can view our Privacy Policy at the main page</span>
        },
        {
            q: 'What is blockchain, and why does Memora use it?',
            a: <span>Blockchain is a digital ledger technology that records transactions in a secure,
            transparent manner. Memora uses blockchain to authenticate items, verify
            ownership, and ensure the transparency of charitable donations.</span>
        },
        {
            q: 'What types of items are available on Memora?',
            a: <span>Memora auctions a range of exclusive celebrity memorabilia, including personal
            items, signed collectibles, limited-edition fashion pieces, artworks, and unique
            experiences.</span>
        },
        {
            q: 'How do I stay informed about upcoming auctions and events?',
            a: <span>Sign up for our newsletter, follow us on social media, and subscribe to our
            Substack to receive weekly updates on the latest in the world of auctions,
            celebrities, memorabilia, philanthropy, luxury, and technology.</span>
        },
        {
            q: 'Who can I contact for support or additional questions',
            a: <span>For any inquiries, please reach out to our support team at&nbsp;
            <a href="mailto:info@memorahouse.io">info@memorahouse.io</a> or use the chat feature on our website. Were here to
            help!</span>
        },
    ]

    return (
        <InnerPageWrapper darkerBackground>
            <Container>
                <Row>
                    <Col xs={12} lg={5} xl={4}>
                        <h1>Frequently Asked Questions</h1>
                    </Col>
                    <Col xs={12} lg={7} xl={8} className="mt-4 mt-lg-0">
                        <FaqAccordion data={data} />
                    </Col>
                </Row>
            </Container>
        </InnerPageWrapper>
    )
}
