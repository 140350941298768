import { Link, useLocation } from 'react-router-dom'
import PathConstants from 'src/routes/pathConstants'
import { Logo } from 'src/assets/images/Logo'

import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { useEffect, useState } from 'react'
import styles from './style.module.css'
import { useModal } from 'src/lib/providers/modal'
import { MODAL } from './Layout'
import { useAuth } from 'src/lib/providers/auth'
import userPhoto from 'src/assets/images/mona_profile.png'
import { avatarStub } from 'src/lib/shared/constants'
// const photoUrl = userPhoto
export default function Header() {
    const [scrolled, setScrolled] = useState(false)
    const { openModal } = useModal()
    const { isLoggedIn, user } = useAuth();
    const [hasPhoto, setHasPhoto] = useState(true)

    const { pathname } = useLocation()

    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 50)
        }

        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    if (pathname.includes('verification')) {
        return null
    }
    return (
        <Navbar
            fixed="top"
            expand="lg"
            className={`${styles.nav} ${scrolled ? styles.scrolled : ''}`}>
            <Container className="d-flex justify-content-between">
                <Nav>
                    <Link
                        className={`nav-link ${styles.link}`}
                        to={PathConstants.HOME}>
                        Home
                    </Link>
                </Nav>
                <Navbar.Brand className={`${styles.logo} mx-auto`}>
                    <Link to={PathConstants.HOME}>
                        <Logo />
                    </Link>
                </Navbar.Brand>
                <Nav>
                    {isLoggedIn ? (
                        <Link
                            to={PathConstants.PROFILE}
                            className={`nav-link ${styles.link}`}>
                            <div
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    borderRadius: ' 50%',
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundImage: `url(${user?.avatar ? user?.avatar : avatarStub})`
                                        // ? // ? `url(${photoUrl})`
                                        //   `url(https://i.pinimg.com/736x/3f/f1/20/3ff120824122616c4922797749f58fba.jpg)`
                                        // : 'none',
                                }}></div>
                        </Link>
                    ) : (
                        <Nav.Link
                            className={`nav-link ${styles.link}`}
                            onClick={() => openModal(MODAL.LOGIN)}>
                            Log in
                        </Nav.Link>
                    )}
                </Nav>
            </Container>
        </Navbar>
    )
}
