import { api } from "../api/client"
import { useState, useEffect } from "react";
import { useAuth } from "../providers/auth";
import { useToast } from "../providers/toast";
import { usePrivateAPICall, usePublicAPI } from "./auctions";

export const useBidsList = ({ lotID, limit=100, offset=0, ordering=""}) => {
  const { data, isLoading, error } = usePublicAPI({
    method: 'get',
    url: '/bids/',
    params: { lot: lotID, limit, offset, ordering }
  });

  return [{ items: data?.results || [], isLoading, error }];
}

export const useBid = (uuid, auction) => {
  const { data, isLoading, error } = usePublicAPI({
    method: 'get',
    url: `/bids/${uuid}/`
  });

  return [{ item: data, isLoading, error }];
}

export const usePlaceBid = (lotID) => {
  const [isLoading, setIsLoading] = useState(false);
  // const [error, setError] = useState(null);
  // const [data, setData] = useState(undefined);
  const { access } = useAuth();
  const { showToast } = useToast();
  const { call: place, data, error, isDone } = usePrivateAPICall({
    method: 'post',
    url: '/bids/'
  });

  async function placeBid({ isAnonymous, amount }) {
    setIsLoading(true);
    // console.log("Run place bid");
    place({
      'is_anonymous': isAnonymous,
      amount,
      'lot': lotID,
    });
  }

  // console.log("Check place bid", isDone, isLoading);
  if (isDone && isLoading) {
    // console.log("REQUEST DONE", error);
    setIsLoading(false);
    if (error) {
      showToast(error.response.data.text);
    }
  }

  return [{ placeBid, item: data, isLoading, error }];
}