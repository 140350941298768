import styles from './style.module.css'
import Label from 'src/components/label'
import moment from 'moment';
import { useEffect, useState } from 'react';
import { toUSD } from 'src/lib/shared/helpers';

export const CURRENCY = "$";

const statusConfig = {
    upcoming: { variant: 'closed', text: 'Coming soon'},
    live: { variant: 'live', text: 'LIVE' },
    livestream: { variant: 'live', text: 'Watch livestream' },
    prebid: { variant: 'prebid', text: 'Accepting pre-bids' },
    closed: { variant: 'closed', text: 'Auction closed' },
};

// to: Moment
export function countdown(to) {
    const now = moment();
    const duration = moment.duration(to.diff(now));
    if (now.isAfter(to)) return `0:0:0:0`;

    return `${duration.days()}:${duration.hours()}:${duration.minutes()}:${duration.seconds()}`;
}

export function formatDate(datestr) {
    console.log("DATE IN", datestr, "DATEOUT", moment(datestr).format("MMMM DD, ha"));
    return moment(datestr).format("MMMM DD, ha")
}

export function formatDateHour(date) {
    return date.format("MMMM DD, ha")
}

export function formatDateMonth(date) {
    return date.format("MMMM YYYY")
}

export function formatDuration(duration) {
    return `${duration.days()}:${duration.hours()}:${duration.minutes()}:${duration.seconds()}`;
}

export const useTimeRemaining = ({ to }) => {
    const dateEnd = moment(to);
    const [timeTo, setTimeTo] = useState(countdown(dateEnd));

    useEffect(() => {
        const id = setTimeout(() => {
        setTimeTo(countdown(dateEnd));
        }, 1000);

        return () => {
        clearTimeout(id);
        };
    });

    return { timeTo }
}

export const TimeRemainingDeprecared = ({ to }) => {
    const dateEnd = moment(to);
    const [timeTo, setTimeTo] = useState(countdown(dateEnd));

    useEffect(() => {
        const id = setTimeout(() => {
        setTimeTo(countdown(dateEnd));
        }, 1000);

        return () => {
        clearTimeout(id);
        };
    });

    return <span>{ timeTo }</span>;
};

const TimeEnds = ({ date, duration }) => {
    return <div className={`${styles.times}`}>
        { duration > 0 ? <Label text="Ends" /> : <Label text="Ended" /> }
        <div className={`${styles.timeContent}`}>{formatDateHour(date)}</div>
    </div>;
};

const TimeStarts = ({ date }) => {
    return <div className={`${styles.times}`}>
        <Label text="Starts" />
        <div className={`${styles.timeContent}`}>{formatDateMonth(date)}</div>
    </div>;
};

function durationUntil(date) {
    const now = moment();
    const duration = moment.duration(date.diff(now));

    return duration;
}

export const useCountdown = (date) => {
    const [duration, setDuration] = useState(durationUntil(date));

    useEffect(() => {
        let id;

        if (duration.isValid) id = setTimeout(() => {
            setDuration(durationUntil(date));
        }, 1000);

        return () => {
            id && clearTimeout(id);
        };
    }, [duration]);

    return { duration };
}

const TimeRemaining = ({ duration, label = "Time remaining" }) => {
    return <div className={`${styles.times}`}>
        <Label text={label} />

        <div className={`${styles.timeContent}`}>
            {`${duration.days()}:${duration.hours()}:${duration.minutes()}:${duration.seconds()}`}
        </div>
    </div>;
};

const TotalRaised = ({ amount }) => {
    return <div className={`${styles.times}`}>
        <Label text="Total raised" />

        <div className={`${styles.timeContent}`}>
            {amount && toUSD(amount)}
        </div>
    </div>;
}

const StatusCol = ({ label, text, columnCSS=styles.times }) => {
    return <div className={columnCSS}>
        <Label text={label} />

        { columnCSS != styles.times
            ? <p>{text}</p>
            : <div className={`${styles.timeContent}`}>{text}</div>
        }
    </div>;
}

export const LotStatus = ( { lot, containerCSS=styles.container, columnCSS=styles.times }) => {
    if (!lot) return <></>;

    const dateEnd = moment(lot['date_end']);
    const { duration: durationEnd } = useCountdown(dateEnd);

    return (
        <div className={containerCSS}>
            { lot.status === 'live' && <>
                <StatusCol label="Current bid" text={lot['last_bid_amount'] && toUSD(lot['last_bid_amount'])} columnCSS={columnCSS}/>
                <StatusCol label="Total bids" text={lot['total_bids']} columnCSS={columnCSS}/>
                { durationEnd > 0 && <StatusCol label="Time remaining" text={formatDuration(durationEnd)} columnCSS={columnCSS}/> }
            </> }
            { lot.status === 'prebid' && <>
                <StatusCol label="Current bid" text={lot['last_bid_amount'] && toUSD(lot['last_bid_amount'])} columnCSS={columnCSS}/>
                <StatusCol label="Total bids" text={lot['total_bids']} columnCSS={columnCSS}/>
            </> }
            { lot.status === 'closed' && <>
                <StatusCol label="Final bid" text="-" columnCSS={columnCSS}/>
                <StatusCol label="Total bids" text="-" columnCSS={columnCSS}/>
            </> }
            { lot.status === 'sold' && <>
                <StatusCol label="Final bid" text={lot['last_bid_amount'] && toUSD(lot['last_bid_amount'])} columnCSS={columnCSS}/>
                <StatusCol label="Total bids" text={lot['total_bids']} columnCSS={columnCSS}/>
            </> }
        </div>
    )
}

const AuctionStatus = ({ auction }) => {
    const auctionStatusCode = auction['status'] === 'live' && auction['livestream_url'] ? 'livestream' : auction['status'];
    const auctionStatus = statusConfig[auctionStatusCode];
    // const status = getStatus(auction);
    const { variant, text: statusText } = auctionStatus;
    // todo: timers?
    // console.log("STATUS", auction)

    if (!auction) return <></>;

    const dateStart = moment(auction['date_start']);
    const dateEnd = moment(auction['date_end']);
    const totalRaised = auction['total_raised'];
    const { duration: durationEnd } = useCountdown(dateEnd);
    const { duration: durationStart } = useCountdown(dateStart);

    return (
        <div className={`${styles.container}`}>
            { auctionStatusCode == 'livestream' && <a href={auction['livestream_url']} target="_blank" className={`${styles.status} ${styles[variant]}`}>
                {statusText}
            </a>
            }
            { auctionStatusCode != 'livestream' && <div className={`${styles.status} ${styles[variant]}`}>
                {statusText}
            </div>
            }
            { auctionStatusCode === 'upcoming' && <>
                <TimeStarts date={dateStart} />
            </> }
            { auction['status'] === 'live' && <>
                <TimeEnds date={dateEnd} duration={durationEnd} />
                { durationEnd > 0 && <TimeRemaining duration={durationEnd} /> }
            </> }
            { auction['status'] === 'prebid' && <>
                <TimeStarts date={dateStart} />
                <TimeEnds date={dateEnd} duration={durationEnd} />
                { durationStart > 0 && <TimeRemaining duration={durationStart} label="Time until start" /> }
            </> }
            { auction['status'] === 'closed' && <>
                <TimeEnds date={dateEnd} duration={durationEnd} />
                <TotalRaised amount={totalRaised}/>
                {/* { durationEnd.isValid && <TimeRemaining duration={durationEnd} /> } */}
            </> }
        </div>
    )
}

export default AuctionStatus
