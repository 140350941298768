import React from 'react'
import styles from './style.module.css'

import Button from '../button'
import { useModal } from 'src/lib/providers/modal'
import { MODAL } from '../layouts/Layout'
import { toUSD } from 'src/lib/shared/helpers'

const ImpactCard = ({ lot }) => {
    const { openModal } = useModal();

    return (
        <div className={styles.card}>
            <div className={styles.titleImageWrapper}>
                {' '}
                <div className={styles.charityInfo}>
                    <h5 className={styles.charityLabel}>THE CHARITY</h5>
                    <h2 className={styles.charityName}>
                        {lot.foundation.title}
                    </h2>
                    <p className={styles.charityDescription}>
                        {lot.foundation['description_short']}
                    </p>
                </div>
                <div className={styles.imageWrapper}>
                    {' '}
                    <img
                        src={lot.foundation.cover_image}
                        alt=""
                        className={styles.racketImage}
                    />
                </div>
            </div>
            <div className={styles.donationBox}>
                <div className={styles.donationInfo}>
                    <h5 className={styles.donationLabel}>YOUR DONATION</h5>
                    <h1 className={styles.donationAmount}>{toUSD(lot.last_bid_amount)}</h1>
                </div>
                <div className={styles.buttons}>
                    <Button variant="neutral-dark" fullWidth={false} onClick={() => openModal(MODAL.CONTENT_MODAL, lot.foundation)}>
                        Charity details
                    </Button>
                    {/* <Button variant="neutral-dark" fullWidth={false}>
                        Tax documents
                    </Button> */}
                </div>
            </div>
        </div>
    )
}

export default ImpactCard
