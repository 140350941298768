import React from 'react'
import styles from './style.module.css'
import Button from '../button'

import tennisImage from 'src/assets/images/tennis.jpg'
import { useNavigate } from 'react-router'
import { toUSD } from 'src/lib/shared/helpers'
const BidCard = ({ bid }) => {
    const isHighest = bid['last_bid_amount'] == bid['my_last_bid_amount']
    const statusMessage = isHighest
        ? 'You are the highest bidder'
        : 'You have been outbid';
    const navigate = useNavigate();
    const lotPath = `/auction/${bid.auction.uuid}/${bid.uuid}`;

    return (
        <div className={styles.card}>
            <div className={styles.cardImage}>
                {' '}
                <img
                    src={bid['cover_media']['media_file']}
                    alt={bid.title}
                    className={styles.image}
                />
            </div>

            <div className={styles.content}>
                <div className={styles.imageContent}>
                    {' '}
                    <div className={styles.subTitle}>{bid.auction.title}</div>
                    <div className={styles.title}>{bid.title}</div>
                </div>

                <div className={styles.bid}>
                    {' '}
                    <div className={styles.bidInfo}>
                        <div className={styles.bidAmountBox}>
                            <p> YOUR BID</p>

                            <div className={styles.bidAmount}>{toUSD(bid['my_last_bid_amount'])}</div>
                        </div>
                        <div className={styles.divider}></div>
                        <div
                            className={`${styles.highestBidder} ${isHighest ? styles.highest : styles.outBid}`}>
                            {statusMessage}
                        </div>
                    </div>
                    <div className={styles.buttons}>
                        <Button
                            variant="dark"
                            style={{ color: '#ff7f47', whiteSpace: 'nowrap' }} onClick={() => navigate(`${lotPath}?show_bids=true`)}>
                            View all bids
                        </Button>

                        <Button variant="primary" style={{ height: '51px' }} onClick={() => navigate(`${lotPath}`)}>
                            Item details
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BidCard
