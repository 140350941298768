import { Col, Container, Row } from 'react-bootstrap'
import { Navigate, useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import TabsComponent from 'src/components/tabs'
import UserHero from 'src/components/user-profile-hero'
import BidList from 'src/components/bidList'
import OwnedList from 'src/components/owned-list'
import ImpactList from 'src/components/impact-card-list'
import LotCard from 'src/components/lot/lot-card'
import { useAuth } from 'src/lib/providers/auth'
import PathConstants from 'src/routes/pathConstants'
import { useMyWatchList } from 'src/lib/hooks/user'
import EmptyList from '../components/empty-list'

const WatchList = () => {
    const [{ items: watchlistItems, isLoading }] = useMyWatchList({});

    // if (isLoading) return <></>;

    return (watchlistItems.length == 0 ? (
        <EmptyList>
            <h4>
                You currently don't have any items on your
                watchlist.
            </h4>
            <p>
                Start exploring our auctions to add lots and
                stay updated on your favorites.
            </p>
        </EmptyList>
    ) : (
        <Container>
            <Row xs={1} md={2} xl={3} className="g-4">
                {watchlistItems.map(lot => (
                    <Col key={lot.uuid}>
                        <LotCard
                            lot={lot}
                            auctionUUID={lot.auction}
                            onClick={() =>
                                console.log('click')
                            }
                        />
                    </Col>
                ))}
            </Row>
        </Container>
    ));
}

export default function Profile() {
    const { user } = useAuth()

    const [activeTab, setActiveTab] = useState('watchlist')
    const location = useLocation()

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const tab = params.get('tab') || 'watchlist'
        setActiveTab(tab)
    }, [location.search])

    const tabs = [
        {
            name: 'Watchlist',
            key: 'watchlist',
        },
        {
            name: 'My bids',
            key: 'myBids',
        },
        {
            name: 'Owned',
            key: 'owned',
        },
        {
            name: 'My impact',
            key: 'myImpact',
        },
    ]

    return (
        <div>
            <UserHero />

            <Container
                style={{
                    paddingBottom: '24px',
                    paddingLeft: '24px',
                    paddingRight: '24px',
                }}>
                <TabsComponent
                    tabs={tabs}
                    active={activeTab}
                    style={{
                        whiteSpace: 'nowrap',
                        overflowX: 'auto',
                        marginBottom: '40px',
                    }}
                />

                <div className="position-relative">
                    {activeTab === 'watchlist' && <WatchList /> }
                    {activeTab === 'myBids' && <BidList type={'myBids'} />}
                    {activeTab === 'owned' && <OwnedList type={'owned'} />}
                    {activeTab === 'myImpact' && (
                        <ImpactList type={'myImpact'} />
                    )}

                    <div
                        style={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                        }}></div>
                </div>
            </Container>
        </div>
    )
}
