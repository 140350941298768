import { createContext, useContext, useEffect, useState } from 'react'
import useLocalStorage from '../hooks/localStorage'
import { useAuth } from "./auth";
import {
  Centrifuge, PublicationContext, SubscriptionStateContext,
  SubscribedContext, SubscriptionState
} from 'centrifuge';

import env from "react-dotenv";

const { API_SOCKET } = env;

const CentrifugeContext = createContext();

export function CentrifugeProvider({ children }) {
    const centrifuge = useProvideCentrifuge();

    return <CentrifugeContext.Provider value={centrifuge}>{children}</CentrifugeContext.Provider>
}

export const useCentrifuge = () => useContext(CentrifugeContext)

function useProvideCentrifuge() {
  const { isLoggedIn, user } = useAuth();
  const [centrifuge, setCentrifuge] = useState(null);

  const [bid, setBid] = useState(null);
  const [lot, setLot] = useState(null);
  const [auction, setAuction] = useState(null);

  const isCentrifugeSet = centrifuge != null;

  function subscribe(centrifuge, channel, handler) {
    const sub = centrifuge.newSubscription(channel, {});
    sub.on('publication', ctx => {
      console.log("received pub to ", channel, ctx.data.content);
      handler(ctx.data.content);
    }).subscribe();
    return sub;
  }

  useEffect(() => {
      if (isLoggedIn && !isCentrifugeSet) {
        const options = {
          token: user.token,
          debug: true
        };

        const c = new Centrifuge(API_SOCKET, options);
        c.connect();

        subscribe(c, 'bids', b => setBid(b));
        subscribe(c, 'lots', b => setLot(b));
        subscribe(c, 'auctions', b => setAuction(b));

        setCentrifuge(c);
      } else {
        if (!isLoggedIn) centrifuge?.disconnect();
      }

      return () => centrifuge?.disconnect();
    }, [isLoggedIn, isCentrifugeSet]);

    return { bid, lot, auction };
}

export const useLotSubscribe = id => {
  const { lot: lotUpdate } = useCentrifuge();
  const [lot, setLot] = useState(null);

  useEffect(() => {
    if (lotUpdate?.uuid == id) setLot(lotUpdate);
  }, [lotUpdate]);

  return { lotUpdate: lot };
}

export const useBidSubscribe = lotId => {
  const { bid: bidUpdate } = useCentrifuge();
  const [bid, setBid] = useState(null);

  useEffect(() => {
    if (bidUpdate?.lot == lotId) setBid(bidUpdate);
  }, [bidUpdate]);

  return { bidUpdate: bid };
}

export const useAuctionLotsSubscribe = (auctionId) => {
  const { lot: lotUpdate } = useCentrifuge();
  const [lot, setLot] = useState(null);

  useEffect(() => {
    if (lotUpdate?.auction == auctionId) setLot(lotUpdate);
  }, [lotUpdate]);

  return { lotUpdate: lot };
}

export const useAuctionSubscribe = (uuid) => {
  const { auction } = useCentrifuge();
  const [auctionUpdate, setAuctionUpdate] = useState(null);

  useEffect(() => {
    if (auction?.uuid == uuid) setAuctionUpdate(auction);
  }, [auction]);

  return { auctionUpdate };
}