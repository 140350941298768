import AuctionDescription from 'src/components/auction/auction-description'
import LotCard from 'src/components/lot/lot-card'
import { Col, Container, Row } from 'react-bootstrap'
import { useParams } from 'react-router'
import { useAuction } from 'src/lib/hooks/auctions'
import Spinner from 'src/components/spinner'
import { useLotsList } from 'src/lib/hooks/lots'
import AuctionImage from '../components/auction/auction-image'
import { useAuctionLotsSubscribe, useAuctionSubscribe } from 'src/lib/providers/socket'
import { useEffect, useState } from 'react'

export default function Auction() {
    const { uuid } = useParams()
    const [
        { item: auctionOriginal, isLoading: isAuctionLoading, error: auctionError },
    ] = useAuction(uuid)
    const [{ items: lots, isLoading: areLotsLoading, error: lotsError }] =
        useLotsList({ auctionUUID: uuid })

    const loading = isAuctionLoading || areLotsLoading

    const { lotUpdate } = useAuctionLotsSubscribe(uuid);
    const { auctionUpdate } = useAuctionSubscribe(uuid);
    const [updates, setUpdates] = useState(new Map());

    const [updatedAuction, setUpdatedAuction] = useState(null);

    useEffect(() => {
        if (lotUpdate != null) {
            setUpdates(new Map(updates).set(lotUpdate.uuid, lotUpdate));
        }
    }, [lotUpdate]);

    useEffect(() => {
        if (auctionUpdate != null) {
            setUpdatedAuction(auctionUpdate);
        }
    }, [auctionUpdate]);

    const lts = lots.map(a => updates.get(a.uuid) || a);

    const auction = updatedAuction || auctionOriginal;

    if (loading || !auction) {
        // temp solution
        return (
            <div
                style={{
                    width: '100%',

                    height: '700px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <Spinner />
            </div>
        )
    }
    // console.log(auction);
    // const auction = {
    //     id: '1',
    //     status: 'live',
    //     title: 'Legends of Sport Björn Borg x Muhammad Ali',
    // }

    return (
        <div>
            <AuctionImage image={auction['cover_media']['media_file']} />

            <AuctionDescription auction={auction} />

            <Container>
                <Row xs={1} md={2} xl={3} className="g-4">
                    {lts.map(lot => (
                        <Col key={lot.uuid}>
                            <LotCard
                                lot={lot}
                                auctionUUID={uuid}
                                onClick={() => console.log('click')}
                            />
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    )
}
