import React from 'react'
import { useNavigate } from 'react-router'
import PathConstants from 'src/routes/pathConstants';

const MemoraLogo = () => {
    const navigate = useNavigate();

    return (
        <svg
            onClick={() => navigate(PathConstants.HOME)}
            width="202"
            height="41"
            viewBox="0 0 202 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M136.894 6.14604C140.271 9.62428 142.316 14.6875 142.316 21.0329C142.316 33.917 133.241 40.793 124.097 40.793C118.838 40.793 114.133 38.9317 110.742 35.4673C107.351 32.0024 105.292 26.9523 105.292 20.6064C105.292 7.66892 114.367 0.792969 123.564 0.792969C128.824 0.792969 133.516 2.66803 136.894 6.14604ZM133.797 33.1602C136.064 30.5477 137.396 26.7968 137.396 22.2591C137.396 16.751 136.058 12.2658 133.609 9.16327C131.166 6.06777 127.6 4.32557 123.084 4.32557C119.288 4.32557 116.077 5.82868 113.812 8.45191C111.544 11.0779 110.212 14.8424 110.212 19.3801C110.212 24.8877 111.563 29.3594 114.018 32.4487C116.468 35.5312 120.034 37.2604 124.524 37.2604C128.321 37.2604 131.532 35.77 133.797 33.1602Z"
                fill="#FF7F47"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M175.366 40.3665H167.853C166.586 40.3665 165.42 39.6731 164.816 38.5597L157.301 24.7228L157.3 24.7214C156.69 23.5806 156.168 22.8303 155.425 22.3536C154.684 21.8781 153.687 21.6529 152.078 21.6529H150.032V31.3227C150.032 35.0636 150.251 37.9224 151.592 39.9851L151.84 40.3665H145.165V10.2633C145.165 6.51952 144.999 3.71677 143.606 1.60208L143.354 1.21949H153.092C155.573 1.21949 159.072 1.36433 161.957 2.62639C163.403 3.25937 164.705 4.17744 165.645 5.50749C166.586 6.83929 167.151 8.56683 167.151 10.7964C167.151 13.3255 166.415 15.5167 164.953 17.2532C163.617 18.8399 161.687 20.0311 159.196 20.7604C161.097 21.6555 161.9 23.1515 162.781 24.7927C162.841 24.9036 162.901 25.0153 162.961 25.1274L167.387 33.2303C167.621 33.6583 167.848 34.062 168.072 34.4453L168.071 34.4426L169.292 36.3109C169.503 36.6328 169.977 36.5977 170.145 36.2669L181.742 5.59385L181.742 5.59235C182.168 4.42208 182.42 3.60486 182.42 2.63925C182.42 2.28674 182.369 1.88322 182.27 1.53413L182.18 1.21949H187.27L196.98 29.8572L196.98 29.8584C197.486 31.3746 198.163 33.2723 198.965 35.0813C199.769 36.8947 200.69 38.6003 201.679 39.745L201.739 39.8145V40.3665H195.267L195.225 40.1721C194.961 38.9597 194.379 37.0523 193.845 35.3989L193.845 35.3971L191.075 27.1444H177.4L175.592 32.0504L175.591 32.0529C174.899 33.8079 174.381 35.5306 174.381 36.974C174.381 38.0571 174.64 39.132 175.147 39.9945L175.366 40.3665ZM150.032 18.4402V4.53884H152.185C154.45 4.53884 156.973 4.70081 158.925 5.62791C159.896 6.08887 160.718 6.73589 161.299 7.64165C161.88 8.54716 162.231 9.72847 162.231 11.2763C162.231 13.9853 161.31 15.7538 159.717 16.8581C158.106 17.9748 155.767 18.4402 152.878 18.4402H150.032ZM190.004 23.825H178.649L184.628 7.75246L190.004 23.825ZM182.577 1.75456C182.557 1.65626 182.534 1.55962 182.507 1.46632C182.53 1.54649 182.55 1.62912 182.568 1.71315C182.571 1.72692 182.574 1.74072 182.577 1.75456Z"
                fill="#FF7F47"
            />
            <path
                d="M51.7682 4.64547H47.1089V18.1736H59.9078V23.2615L59.5211 22.9957C57.8792 21.8672 56.0239 21.4397 51.7149 21.4397H47.1089V30.2564C47.1089 33.1092 47.2119 35.2084 47.8825 36.9405H53.1548C58.5416 36.9405 61.0186 36.5624 63.2909 34.7037L63.3592 34.6479H63.9424L63.1836 40.3665H42.3488V10.2633C42.3488 6.51952 42.1826 3.71677 40.7896 1.60208L40.5376 1.21949H61.3692L62.1308 6.72475H61.6046L61.5386 6.67486C59.2112 4.91685 56.9963 4.64547 51.7682 4.64547Z"
                fill="#FF7F47"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.52595 6.19456L0 40.3665H5.53929L5.33433 39.9993C4.09204 37.7741 3.97899 35.2788 4.19179 31.9799L4.19189 31.9783L5.76556 11.5771L17.6994 40.3665H18.9318L31.5492 11.816L33.4185 40.3665H39.3993L39.1992 40.0011C38.004 37.8192 37.8433 35.4261 37.6292 31.9473L35.5873 1.21949H31.4297V1.46632C31.4297 2.90962 30.8581 4.7392 30.3302 5.95299L19.0972 31.5993L6.51028 1.21949H2.07573L2.16078 1.53127C2.47336 2.6771 2.68405 3.87556 2.526 6.19383L2.52595 6.19456ZM2.39898 1.46632C2.62915 2.31008 2.80414 3.18142 2.82467 4.47727C2.80613 3.30719 2.66166 2.48322 2.46434 1.71315C2.44313 1.63037 2.42132 1.54822 2.39898 1.46632Z"
                fill="#FF7F47"
            />
            <path
                d="M65.5266 40.3665L68.0526 6.19456L68.0526 6.19383C68.2107 3.87556 68 2.6771 67.6874 1.53127L67.6024 1.21949H72.0369L84.6239 31.5993L95.8569 5.95299C96.3848 4.7392 96.9563 2.90962 96.9563 1.46632V1.21949H101.114L103.156 31.9473C103.37 35.4261 103.531 37.8192 104.726 40.0011L104.926 40.3665H98.9451L97.0759 11.816L84.4584 40.3665H83.2261L71.2922 11.5771L69.7185 31.9783L69.7184 31.9799C69.5056 35.2788 69.6187 37.7741 70.861 39.9993L71.0659 40.3665H65.5266Z"
                fill="#FF7F47"
            />
        </svg>
    )
}

export default MemoraLogo
