import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import styles from './style.module.css'
import Button from 'src/components/button'
import { useAuth } from 'src/lib/providers/auth'
import { useModal } from 'src/lib/providers/modal'
import { MODAL } from '../layouts/Layout'

const Hero = () => {
    const { openModal } = useModal();
    const { isLoggedIn } = useAuth();

    let buttonText = "Become a Member";
    if (isLoggedIn) buttonText = "Discover";

    function scrollToAuctions() {
        if (isLoggedIn) {
            const container = document.getElementById('auctions-explore');
            container?.scrollIntoView();
        } else {
            openModal(MODAL.REGISTER);
        }
    }

    return (
        <div className={`${styles.heroSection}`}>
            <Container>
                <Row>
                    <Col>
                        <h1>
                            Memorable items,
                            <br />
                            meaningful impact.
                        </h1>
                        <div className="d-flex justify-content-center mt-5">
                            <Button variant="primary" fullWidth={false} onClick={() => scrollToAuctions() }>
                                {buttonText}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Hero
