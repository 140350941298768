import { useTimeRemaining } from 'src/components/auction/auction-status';
import { formatDate } from '../latest-bids';
import styles from './style.module.css'
import Label from 'src/components/label'
import { toUSD } from 'src/lib/shared/helpers';

const BidStatus = ({ lot, bids }) => {
    // get data from react context?

    const lastBid = bids.length && bids[0];
    const { timeTo } = useTimeRemaining({ to: lot['date_end'] });
    const isSold = lot.status === 'sold';

    return (
        <div className={styles.status}>
            <div>
                { isSold && <Label text="Ended" /> }
                { !isSold && <Label text="Ends" /> }
                <div>{formatDate(lot['date_end'])}</div>
            </div>
            <div>
                <Label text="Remaining" />
                <div>{timeTo}</div>
            </div>
            <div>
                { isSold && <Label text="Winning bid" /> }
                { !isSold && <Label text="Current bid" /> }

                <div>{toUSD (lastBid?.amount ?? 0)}</div>
            </div>
            <div>
                <Label text="Total bids" />
                <div>{bids.length}</div>
            </div>
        </div>
    )
}

export default BidStatus
