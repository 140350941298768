import { useEffect, useState } from 'react'
import Spinner from 'src/components/spinner'
import { fetchData } from 'src/data/api'
import AuctionCard from 'src/components/auction/auction-card'
import { useAuctionsList } from 'src/lib/hooks/auctions'
import { useCentrifuge } from 'src/lib/providers/socket'

const AuctionList = ({ type = 'current' }) => {
    const [{ items: auctions, isLoading: loading, error }] = useAuctionsList()
    const [updates, setUpdates] = useState(new Map());
    const { auction: auctionUpdate } = useCentrifuge();

    useEffect(() => {
        if (auctionUpdate != null) {
            setUpdates(new Map(updates).set(auctionUpdate.uuid, auctionUpdate));
        }
    }, [auctionUpdate]);

    const aucs = auctions.map(a => updates.get(a.uuid) || a);

    if (loading) {
        // temp solution
        return (
            <div
                style={{
                    width: '100%',

                    height: '700px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <Spinner />
            </div>
        )
    }

    return (
        <div className="position-relative">
            {aucs.map(auction => {
                return <AuctionCard key={auction.uuid} auction={auction} />
            })}
        </div>
    )
}

export default AuctionList
