/* eslint-disable react/prop-types */

import React from 'react'
import styles from './style.module.css'

const SignContent = ({
    step = '1',
    buttonText = 'Continue',
    buttonAction = () => {},
    children = null,
    isStepDefined = true,
}) => (
    <>
        <div className={styles.forgotWrapper}>
            {children}
            <button type="submit" className={styles.btn} onClick={buttonAction}>
                {buttonText}
            </button>
        </div>
        {isStepDefined && <div className={styles.steps}>STEP {step} of 4</div>}
    </>
)

export default SignContent
