import { createContext, useContext, useEffect, useState } from 'react'
import { MOBILE_BREAKPOINT } from 'src/config';

const BreakpointContext = createContext();

export function BreakpointProvider({ children }) {
    const centrifuge = useProvideBreakpoint();

    return <BreakpointContext.Provider value={centrifuge}>{children}</BreakpointContext.Provider>
}

export const useBreakpoint = () => useContext(BreakpointContext)

function useProvideBreakpoint() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < MOBILE_BREAKPOINT);

  useEffect(() => {
    const listener = () => {
      setIsMobile(window.innerWidth < MOBILE_BREAKPOINT);
    }

    window.addEventListener('resize', listener);

    return () => window.removeEventListener('resize', listener);
  }, []);

  return { isMobile };
}